var $ = require('jquery');
global.$ = global.jQuery = $;

// Load in Bootstrap Modal JS file for global DPSG footer modals
require('bootstrap-sass/assets/javascripts/bootstrap/modal');

$(function() {

    // Footer Logo
    $(".home #footer .logo").click(function(e) {
        e.preventDefault();
        scrollTo('body');
    });

    // Navigation
    var $navContainer = $(".nav-mobile, .nav-main, .nav-overlay");

    $(".nav-mobile, .nav-overlay").click(function() {
        $navContainer.toggleClass("open");
    });

    function closeNav() {
        $navContainer.removeClass("open");
    }
    
    $(window).on("scroll resize", closeNav);
    
    $(".nav-main li a, .down").click(function(e) {
        e.preventDefault();

        var href = $(this).attr('href');
        
        if ( typeof href !== typeof undefined && href !== false ) {
            scrollTo($(this).attr('href'));
        } else {
            scrollTo($('body').find('section')[0]);
        }
    });

    // Scroll to element
    function scrollTo(element, offset, speed, callback) {

        if (offset !== 'undefined') {
            offset = 0;
        }

        if (element !== 'undefined' && element){

            var dfd = $.Deferred().done(callback);

            $('html, body').animate({
                scrollTop: $(element).offset().top - offset
            }, speed, 'swing', dfd.resolve);
        }
    }
});
